export const domainConfig = {
    'en-GB': {
        backendDomain: 'whereithappened.co.uk',
        gaPixel: 'G-2L33KD04K2',
        dateFormat: 'MMMM D, YYYY',
        decimalSeperator: '.',
        valuta: '£',
        valutaPosition: 'left',
        timeFormat: 'h:mm A',
        requiresHouseNumber: false,
        enablePhoneNumberField: true,
        fastShipping: false,
        tawkId: "646b5b5e74285f0ec46ce752/1h11lpqm3",
        showSizesAs: 'code',
        instagram: 'https://www.instagram.com/whereithappeneduk/',
        facebook: 'https://www.facebook.com/whereithappeneduk',
        acceptedLocales: ['GB']
    },
    'nl': {
        backendDomain: 'whereithappened.nl',
        gaPixel: 'G-40CR14FGH8',
        dateFormat: 'D MMMM YYYY',
        decimalSeperator: ',',
        valuta: '€',
        valutaPosition: 'left',
        timeFormat: 'h:mm',
        requiresHouseNumber: true,
        enablePhoneNumberField: false,
        fastShipping: true,
        tawkId: "6043c9501c1c2a130d65b748/1f04ckoqk",
        showSizesAs: 'sizes',
        instagram: 'https://www.instagram.com/whereithappenednl/',
        facebook: 'https://www.facebook.com/whereithappenednl',
        acceptedLocales: ['NL', 'BE']
    },
    'de': {
        backendDomain: 'whereithappened.de',
        gaPixel: 'G-HK6Y0RC6LW',
        dateFormat: 'D. MMMM YYYY',
        decimalSeperator: ',',
        valuta: '€',
        valutaPosition: 'right_space',
        timeFormat: 'h:mm',
        requiresHouseNumber: true,
        enablePhoneNumberField: false,
        fastShipping: true,
        tawkId: "6161bc63157d100a41ab971c/1fhisfq4p",
        showSizesAs: 'sizes',
        instagram: 'https://www.instagram.com/whereithappened_de/',
        facebook: 'https://www.facebook.com/WhereItHappenedde',
        acceptedLocales: ['DE', 'AT', 'CH']
    },
};