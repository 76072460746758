import { v4 } from 'uuid';
import { triggerRemoveFromCartEvent } from './events/executors';
import { domainConfig } from '../domains.config';
/**
 * Extracts and returns float value from a string.
 *
 * @param {string} string String
 * @return {any}
 */
export const getFloatVal = (string) => {
	let floatValue = string.match(/[+-]?\d+((\.\d+)|(\,\d+))?/g)[0];
	if (floatValue[floatValue.length - 3] === ',') {
		floatValue = floatValue.replace(/,/g, '.');
	}
	const result = (null !== floatValue) ? parseFloat(parseFloat(floatValue).toFixed(2)) : '';
	return result;
};

/**
 * Add first product.
 *
 * @param {Object} product Product
 * @return {{totalProductsCount: number, totalProductsPrice: any, products: Array}}
 */
export const addFirstProduct = (product) => {

	let productPrice = getFloatVal(product.price);

	let newCart = {
		products: [],
		totalProductsCount: 1,
		totalProductsPrice: productPrice
	};

	const newProduct = createNewProduct(product, productPrice, 1);
	newCart.products.push(newProduct);

	localStorage.setItem('woo-next-cart', JSON.stringify(newCart));

	return newCart;
};

/**
 * Create a new product object.
 *
 * @param {Object} product Product
 * @param {Integer} productPrice Product Price
 * @param {Integer} qty Quantity
 * @return {{image: *, productId: *, totalPrice: number, price: *, qty: *, name: *}}
 */
export const createNewProduct = (product, productPrice, qty) => {

	return {
		productId: product.productId,
		image: product.image,
		name: product.name,
		price: productPrice,
		qty,
		totalPrice: parseFloat((productPrice * qty).toFixed(2))
	};

};

/**
 * Updates the existing cart with new item.
 *
 * @param {Object} existingCart Existing Cart.
 * @param {Object} product Product.
 * @param {Integer} qtyToBeAdded Quantity.
 * @param {Integer} newQty New Qty to be updated.
 * @return {{totalProductsCount: *, totalProductsPrice: *, products: *}}
 */
export const updateCart = (existingCart, product, qtyToBeAdded, newQty = false) => {

	const updatedProducts = getUpdatedProducts(existingCart.products, product, qtyToBeAdded, newQty);

	const addPrice = (total, item) => {
		total.totalPrice += item.totalPrice;
		total.qty += item.qty;

		return total;
	};

	// Loop through the updated product array and add the totalPrice of each item to get the totalPrice
	let total = updatedProducts.reduce(addPrice, { totalPrice: 0, qty: 0 });

	const updatedCart = {
		products: updatedProducts,
		totalProductsCount: parseInt(total.qty),
		totalProductsPrice: parseFloat(total.totalPrice)
	};

	localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));

	return updatedCart;
};

/**
 * Get updated products array
 * Update the product if it exists else,
 * add the new product to existing cart,
 *
 * @param {Object} existingProductsInCart Existing product in cart
 * @param {Object} product Product
 * @param {Integer} qtyToBeAdded Quantity
 * @param {Integer} newQty New qty of the product (optional)
 * @return {*[]}
 */
export const getUpdatedProducts = (existingProductsInCart, product, qtyToBeAdded, newQty = false) => {

	// Check if the product already exits in the cart.
	const productExitsIndex = isProductInCart(existingProductsInCart, product.productId);

	// If product exits ( index of that product found in the array ), update the product quantity and totalPrice
	if (-1 < productExitsIndex) {
		let updatedProducts = existingProductsInCart;
		let updatedProduct = updatedProducts[productExitsIndex];

		// If have new qty of the product available, set that else add the qtyToBeAdded
		updatedProduct.qty = (newQty) ? parseInt(newQty) : parseInt(updatedProduct.qty + qtyToBeAdded);
		updatedProduct.totalPrice = parseFloat((updatedProduct.price * updatedProduct.qty).toFixed(2));

		return updatedProducts;
	} else {

		// If product not found push the new product to the existing product array.
		let productPrice = getFloatVal(product.price);
		const newProduct = createNewProduct(product, productPrice, qtyToBeAdded);
		existingProductsInCart.push(newProduct);

		return existingProductsInCart;
	}
};

/**
 * Returns index of the product if it exists.
 *
 * @param {Object} existingProductsInCart Existing Products.
 * @param {Integer} productId Product id.
 * @return {number | *} Index Returns -1 if product does not exist in the array, index number otherwise
 */
const isProductInCart = (existingProductsInCart, productId) => {

	const returnItemThatExits = (item, index) => {
		if (productId === item.productId) {
			return item;
		}
	};

	// This new array will only contain the product which is matched.
	const newArray = existingProductsInCart.filter(returnItemThatExits);

	return existingProductsInCart.indexOf(newArray[0]);
};

/**
 * Remove Item from the cart.
 *
 * @param {Integer} productId Product Id.
 * @return {any | string} Updated cart
 */
export const removeItemFromCart = (productId) => {

	let existingCart = localStorage.getItem('woo-next-cart');
	existingCart = JSON.parse(existingCart);

	// If there is only one item in the cart, delete the cart.
	if (1 === existingCart.products.length) {

		localStorage.removeItem('woo-next-cart');
		return null;

	}

	// Check if the product already exits in the cart.
	const productExitsIndex = isProductInCart(existingCart.products, productId);

	// If product to be removed exits
	if (-1 < productExitsIndex) {

		const productTobeRemoved = existingCart.products[productExitsIndex];
		const qtyToBeRemovedFromTotal = productTobeRemoved.qty;
		const priceToBeDeductedFromTotal = productTobeRemoved.totalPrice;

		// Remove that product from the array and update the total price and total quantity of the cart
		let updatedCart = existingCart;
		updatedCart.products.splice(productExitsIndex, 1);
		updatedCart.totalProductsCount = updatedCart.totalProductsCount - qtyToBeRemovedFromTotal;
		updatedCart.totalProductsPrice = updatedCart.totalProductsPrice - priceToBeDeductedFromTotal;

		localStorage.setItem('woo-next-cart', JSON.stringify(updatedCart));
		return updatedCart;

	} else {
		return existingCart;
	}
};

export const getBreadCrumbs = (item, categoryData) => {
	let crumbs = [];
	if (categoryData?.parentId !== null) {
		let parentCrumbs = categoryData?.ancestors?.nodes?.slice()?.sort((a, b) => {
			if (a.id === b?.parentId) {
				return -1;
			}
			if (a?.parentId === null) {
				return -1;
			}
			return 1;
		})?.map(category => {
			return {
				name: category.name,
				slug: category.uri
			}
		});
		crumbs.push(...parentCrumbs, {
			slug: categoryData.uri,
			name: categoryData.name
		}, {
			slug: '',
			name: item.name
		});
	} else {
		crumbs.push({
			slug: categoryData.uri,
			name: categoryData.name
		}, {
			slug: '',
			name: item.name
		})
	}
	return crumbs
};

export const getCategoryBannerImage = (categoryData) => {
	let bannerImage = null;
	if (categoryData?.productCategoryAcf?.bannerimage?.sourceUrl) {
		bannerImage = categoryData?.productCategoryAcf?.bannerimage?.sourceUrl;
	} else {
		let ancestors = categoryData?.ancestors?.nodes;
		if (ancestors) {
			let ancestor = ancestors.find(ancestor => ancestor?.productCategoryAcf?.bannerimage?.sourceUrl);
			if (ancestor) {
				bannerImage = ancestor?.productCategoryAcf?.bannerimage?.sourceUrl;
			}
		}
	}
	return bannerImage;
};

export const getCategoryDescription = (categoryData) => {
	let description = null;
	if (categoryData?.description) {
		description = categoryData?.description;
	} else {
		let ancestors = categoryData?.ancestors?.nodes;
		if (ancestors) {
			let ancestor = ancestors.find(ancestor => ancestor?.description);
			if (ancestor) {
				description = ancestor?.description;
			}
		}
	}
	return description;
};


export const getCategoryBreadCrumbs = (categoryItem) => {
	let crumbs = [];
	if (categoryItem?.parentId !== null) {
		let parentCrumbs = categoryItem?.ancestors?.nodes?.slice()?.sort((a, b) => a.uri.length - b.uri.length)?.map(category => {
			return {
				name: category.name,
				slug: category.uri
			}
		});
		crumbs.push(...parentCrumbs, {
			slug: '',
			name: categoryItem.name
		});
	} else {
		crumbs.push({
			slug: '',
			name: categoryItem.name
		})
	}
	return crumbs
};

export const formatStringWithStreepje = (str) => {
	return str.replaceAll(' ', '-').toLowerCase();
};

/**
 * Returns cart data in the required format.
 * @param {String} data Cart data
 */

export const numberToLocaleString = (number, locale) => {
	const currDomainConfig = domainConfig[locale];
	const decimalSeperator = currDomainConfig.decimalSeperator;
	const valuta = currDomainConfig.valuta;
	const valutaPosition = currDomainConfig.valutaPosition;

	if (valutaPosition === 'left') {
		return `${valuta}${number.toFixed(2).replace('.', decimalSeperator)}`;
	}
	else if (valutaPosition === 'left_space') {
		return `${valuta} ${number.toFixed(2).replace('.', decimalSeperator)}`;
	}
	else if (valutaPosition === 'right') {
		return `${number.toFixed(2).replace('.', decimalSeperator)}${valuta}`;
	}
	else if (valutaPosition === 'right_space') {
		return `${number.toFixed(2).replace('.', decimalSeperator)} ${valuta}`;
	}
	else {
		return `${valuta} ${number.toFixed(2).replace('.', decimalSeperator)}`;
	}
};

export const amountStringToFloat = (amountString, locale) => {
	if (!amountString) return 0;
	const currDomainConfig = domainConfig[locale];
	const decimalSeperator = currDomainConfig.decimalSeperator;
	const valuta = currDomainConfig.valuta;

	const amountStringWithoutValuta = amountString.replace(valuta, '');
	const amountStringWithDot = amountStringWithoutValuta.replace(decimalSeperator, '.');
	const amountFloat = parseFloat(amountStringWithDot);
	return amountFloat;
};

export const isZero = (amountString, locale) => {
	const amountFloat = amountStringToFloat(amountString, locale);
	return amountFloat === 0;
};


export const getFormattedCart = (data, locale) => {
	let formattedCart = null;

	if (undefined === data || !data.cart.contents.nodes.length) {
		return formattedCart;
	}

	const givenProducts = data.cart.contents.nodes;
	const appliedCoupons = data.cart.appliedCoupons ?? [];

	// Create an empty object.
	formattedCart = {};
	formattedCart.products = [];
	let totalProductsCount = 0;

	for (let i = 0; i < givenProducts.length; i++) {
		const givenProduct = givenProducts?.[i]?.product?.node;
		const product = {};
		if (givenProducts?.[i]?.subtotalTax) {
			const subtotalPrice = amountStringToFloat(givenProducts?.[i]?.subtotal, locale);
			const subtotalTax = amountStringToFloat(givenProducts?.[i]?.subtotalTax, locale);
			product.totalPrice = numberToLocaleString(subtotalPrice + subtotalTax, locale);
		}
		else {
			product.totalPrice = givenProducts?.[i]?.subtotal ?? '';
		}
		const total = getFloatVal(product.totalPrice);
		product.productId = givenProduct?.productId ?? '';
		product.cartKey = givenProducts?.[i]?.key ?? '';
		product.name = givenProduct?.name ?? '';
		product.qty = givenProducts?.[i]?.quantity;
		product.price = total / product?.qty;
		product.image = {
			sourceUrl: givenProduct?.image?.sourceUrl ?? '',
			srcSet: givenProduct?.image?.srcSet ?? '',
			title: givenProduct?.image?.title ?? '',
			altText: givenProduct?.image?.altText ?? ''
		};
		product.slug = givenProduct?.slug ?? '';
		product.variation = givenProducts?.[i]?.variation ? givenProducts?.[i]?.variation : null;

		product.meta = {
			text: givenProducts?.[i].extraData.find(obj => obj.key === 'text'),
			text2: givenProducts?.[i].extraData.find(obj => obj.key === 'text2'),
			...(givenProducts?.[i].extraData.find(obj => obj.key === 'text3') && { text3: givenProducts?.[i].extraData.find(obj => obj.key === 'text3') }),
			preview: givenProducts?.[i].extraData.find(obj => obj.key === 'mappreview'),
			preview_small: givenProducts?.[i].extraData.find(obj => obj.key === 'mappreview_small'),
			size: givenProducts?.[i].extraData.find(obj => obj.key === 'size'),
			sizeString: givenProducts?.[i].extraData.find(obj => obj.key === 'sizeString'),
			formattedPrice: givenProducts?.[i].extraData.find(obj => obj.key === 'formattedPrice'),
			frameString: givenProducts?.[i].extraData.find(obj => obj.key === 'frameString'),
			frame: givenProducts?.[i].extraData.find(obj => obj.key === 'frame'),
			shortFrameString: givenProducts?.[i].extraData.find(obj => obj.key === 'shortFrameString'),
			materialHangingSystemString: givenProducts?.[i].extraData.find(obj => obj.key === 'materialHangingSystemString'),
			materialHangingSystemCode: givenProducts?.[i].extraData.find(obj => obj.key === 'hangingSystemCode'),
			editUrl: givenProducts?.[i].extraData.find(obj => obj.key === 'editUrl'),
			isHorizontal: givenProducts?.[i].extraData.find(obj => obj.key === 'isHorizontal'),
			outputSizes: {
				width: givenProducts?.[i].extraData.find(obj => obj.key === "outputWidth"),
				height: givenProducts?.[i].extraData.find(obj => obj.key === "outputHeight")
			},
			material: givenProducts?.[i].extraData.find(obj => obj.key === "materialString")
		}

		totalProductsCount += givenProducts?.[i]?.quantity;

		// Push each item into the products array.
		formattedCart.products.push(product);
	}

	if (data?.cart?.subtotalTax) {
		const subtotalPrice = amountStringToFloat(data.cart.subtotal, locale);
		const subtotalTax = amountStringToFloat(data.cart.subtotalTax, locale);
		formattedCart.subTotalProductsPrice = numberToLocaleString(subtotalPrice + subtotalTax, locale)
	}
	else {
		formattedCart.subTotalProductsPrice = data?.cart?.subtotal ?? '';
	}
	formattedCart.totalProductsCount = totalProductsCount;
	formattedCart.totalProductsPrice = (data?.cart?.total ?? '').replace("&nbsp;", " ");

	formattedCart.appliedCoupons = appliedCoupons.filter(coupon => !isZero(coupon.discountAmount, locale)).map(coupon => {
		const discountAmount = amountStringToFloat(coupon.discountAmount, locale);
		const subtotalTax = amountStringToFloat(coupon.discountTax, locale);
		return {
			code: coupon.code,
			discountAmount: numberToLocaleString(discountAmount + subtotalTax, locale),
		}
	});

	const discountTotal = amountStringToFloat(data?.cart?.discountTotal, locale);
	const discountTax = amountStringToFloat(data?.cart?.discountTax, locale);
	formattedCart.discountTotal = data?.cart?.discountTotal ? numberToLocaleString(discountTotal + discountTax, locale) : '';

	const shippingTotal = amountStringToFloat(data?.cart?.shippingTotal, locale);
	const shippingTax = amountStringToFloat(data?.cart?.shippingTax, locale);
	formattedCart.shippingCosts = data?.cart?.shippingTotal ? numberToLocaleString(shippingTotal + shippingTax, locale) : '';
	return formattedCart;

};

/**
 * 
 * @param {*} isHorizontal 
 * @param {*} height 
 * @param {*} width 
 * @param {*} maxSize 
 */
export const calculatePreviewSize = (isHorizontal, height, width, maxSize) => {
	if (isHorizontal === '1' || isHorizontal === 1) {
		const ratio = maxSize / width;
		const newHeight = width * ratio;
		const newWidth = height * ratio;
		return {
			height: newHeight,
			width: newWidth
		}
	} else {
		const ratio = maxSize / height;
		const newWidth = width * ratio;
		const newHeight = height * ratio;
		return {
			height: newHeight,
			width: newWidth
		}
	}
};

export const parsePriceStringToNumber = (price) => {
	// Remove all non-numeric characters and convert the string to a number
	return Number(price.replace(/[^0-9,-]+/g, "").replace(',', '.'));
}

export const createCheckoutData = (order) => {

	// Set the shipping Data to billing, if applicable.
	const shippingData = order.shippingDifferentThanBilling ? order.shipping : order.billing;

	const checkoutData = {
		clientMutationId: v4(),
		shipping: {
			firstName: shippingData?.firstName,
			lastName: shippingData?.lastName,
			address1: shippingData?.address1,
			address2: shippingData?.houseno,
			city: shippingData?.city,
			country: shippingData?.country,
			state: shippingData?.state,
			postcode: shippingData?.postcode,
			email: shippingData?.email,
			phone: shippingData?.phone,
			company: shippingData?.company
		},
		billing: {
			firstName: order?.billing?.firstName,
			lastName: order?.billing?.lastName,
			address1: order?.billing?.address1,
			address2: order?.billing?.houseno,
			city: order?.billing?.city,
			country: order?.billing?.country,
			state: order?.billing?.state,
			postcode: order?.billing?.postcode,
			email: order?.billing?.email,
			phone: order?.billing?.phone,
			company: order?.billing?.company
		},
		shipToDifferentAddress: order.shippingDifferentThanBilling,
		paymentMethod: order.paymentMethod,
		isPaid: false,
	};
	if (order?.paymentIssuer) {
		checkoutData.metaData = [{ key: "paymentIssuer", value: order.selectedIssuer?.id }];
	}

	return checkoutData;
};

/**
 * Get the updated items in the below format required for mutation input.
 *
 * [
 * { "key": "33e75ff09dd601bbe6dd51039152189", "quantity": 1 },
 * { "key": "02e74f10e0327ad868d38f2b4fdd6f0", "quantity": 1 },
 * ]
 *
 * Creates an array in above format with the newQty (updated Qty ).
 *
 */
export const getUpdatedItems = (products, newQty, cartKey) => {

	// Create an empty array.
	const updatedItems = [];

	// Loop through the product array.
	products.map((cartItem) => {

		// If you find the cart key of the product user is trying to update, push the key and new qty.
		if (cartItem.cartKey === cartKey) {
			triggerRemoveFromCartEvent(cartItem.price, cartItem.productId, cartItem.name);
			updatedItems.push({
				key: cartItem.cartKey,
				quantity: parseInt(newQty)
			});

			// Otherwise just push the existing qty without updating.
		} else {
			updatedItems.push({
				key: cartItem.cartKey,
				quantity: cartItem.qty
			});
		}
	});

	// Return the updatedItems array with new Qtys.
	return updatedItems;

};

/**
 * 
 * @param {Number} maxWidth Maximum poster width
 * @param {Number} marginPercent Percent margin to the edge of the screen
 * @returns 
 */
export const scalePoster = (maxWidth, maxHeight, marginPercent, isHorizontal = false, defaultWidth = window.screen.width, defaultHeight = window.screen.height, heightMarginPercent = 10) => {
	if (isHorizontal) {
		const oldMaxWidth = maxWidth;
		maxWidth = maxHeight;
		maxHeight = oldMaxWidth;
	}

	let widthScale,
		width = defaultWidth,
		widthMargin = width * marginPercent / 100,
		widthIsMax = false;
	widthScale = width / (maxWidth + widthMargin);

	let heightScale,
		height = defaultHeight,
		heightMargin = height * heightMarginPercent / 100,
		heightIsMax = false;
	heightScale = height / (maxHeight + heightMargin);
	const scale = Math.min(widthScale, heightScale);

	return {
		// scale: widthIsMax ? 1 : widthScale,
		// width: widthIsMax? maxWidth : maxWidth * widthScale,
		// height: widthIsMax? maxHeight : maxHeight * widthScale
		scale: widthIsMax && heightIsMax ? 1 : scale,
		width: widthIsMax && heightIsMax ? maxWidth : maxWidth * scale,
		height: widthIsMax && heightIsMax ? maxHeight : maxHeight * scale
	}
};

export const formatMaterialData = (materialDataArray, sizes) => {
	let newStreetmapMaterialData = [];
	materialDataArray.forEach((material, i) => {
		let newMaterial = { ...material };
		newStreetmapMaterialData = [...newStreetmapMaterialData, newMaterial];
		material.materialSizes.forEach((materialSize, y) => {
			const size = sizes.find(size => size.id === materialSize.size_id);
			if (!size) return;
			let newMaterialSize = {
				...materialSize,
				output_height: size?.output_height,
				output_width: size?.output_width,
				preview_height: size?.preview_height /*1.3*/,
				preview_width: size?.preview_width /*1.3*/,
				sizeCode: size?.code,
			}
			newStreetmapMaterialData[i].filteredMaterialSizes ? newStreetmapMaterialData[i].filteredMaterialSizes = [...newStreetmapMaterialData[i].filteredMaterialSizes, ...[newMaterialSize]] : newStreetmapMaterialData[i].filteredMaterialSizes = [newMaterialSize];
		})
	});
	return newStreetmapMaterialData;
}

export const findPairsByPrefix = (object, prefix) => {
	const keys = Object.keys(object);
	const pairs = keys.reduce((acc, key) => {
		if (key.startsWith(prefix)) {
			const keyWithoutPrefix = key.replace(prefix, '');
			acc[keyWithoutPrefix] = object[key];
		}
		return acc;
	}, {});

	return pairs;
}

// Used to get the data from a cookie
export function check_cookie_name(name) {
	if (typeof document === 'undefined') return;
	var match = document?.cookie?.match(new RegExp('(^| )' + name + '=([^;]+)'));
	if (match) {
		return match[2];
	}
}

export const generatePositions = (text) => {
	const numRows = numRowsMap[text.length] || 7;
	const positions = Array(numRows).fill().map(() => []);
	const avarageItemsPerRow = Math.floor(text.length / numRows);

	let row = 0;
	let col = 0;

	for (let i = 0; i < text.length; i++) {
		positions[row][col] = text[i];
		col++;

		if (col === Math.ceil(text.length / numRows)) {
			col = 0;
			row++;
		}
	}
	// check if last in array is less than avarageItemsPerRow, if so move item to last row
	if (positions[numRows - 1].length < avarageItemsPerRow) {
		for (let i = 0; i < positions.length - 1; i++) {
			const lastItem = positions[i].pop();
			positions[i + 1].unshift(lastItem);
		}
	}

	return positions;
}