export const scrollToButton = (buttonRef) => {
    if (!buttonRef.current) return;
    if (typeof window === 'undefined') return;
    window.scrollTo({
        top: (window.pageYOffset + buttonRef.current.getBoundingClientRect().top - window.innerHeight) + buttonRef.current.offsetHeight,
        behavior: 'smooth'
    });
}

export const findObjectByKey = (array, key, value) => {
    for (let i = 0; i < array.length; i++) {
        if (array[i][key] === value) {
            return array[i];
        }
    }
    return null;
}

export function getParameterByName(name, url = "") {
    if (typeof window == "undefined") return;
    if (!url) url = window.location.href;
    const deserializedName = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + deserializedName + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

const toDegreesMinutesAndSeconds = coordinate => {
    const absolute = Math.abs(coordinate);
    const degrees = Math.floor(absolute);
    const minutesNotTruncated = (absolute - degrees) * 60;
    const minutes = Math.floor(minutesNotTruncated);
    const seconds = Math.round((minutesNotTruncated - minutes) * 60);

    return degrees + "°" + minutes + "'" + seconds;
}

export const convertDMS = (lat, lng, t) => {
    const latitude = toDegreesMinutesAndSeconds(lat);
    const latitudeCardinal = lat >= 0 ? t('dms.north', { ns: "mapproduct" }) : t('dms.south', { ns: "mapproduct" });

    const longitude = toDegreesMinutesAndSeconds(lng);
    const longitudeCardinal = lng >= 0 ? t('dms.east', { ns: "mapproduct" }) : t('dms.west', { ns: "mapproduct" });

    return {
        lat: latitude + '"' + latitudeCardinal,
        lng: longitude + '"' + longitudeCardinal
    }
}

export const removeDisallowedChars = str => {
    return str.replace(/[^-+.,!@#$%\^&*()=/`‘~;'"°{}´¨:`àèìòùˆåâêîôûäëïöü\náéíúóàèìòùÀÈÌÒÙÅÂÊÎÔÛÄËÏÖÜÁÉÍÚÓÀÈÌÒÙ_?| a-zA-Z0-9]/g, "");
}

/**
  * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
  * 
  * @param {String} text The text to be rendered.
  * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
  * 
  * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
  */
export const getTextWidth = (text, font, line) => {
    // re-use canvas object for better performance
    if (!getTextWidth.canvas) {
        getTextWidth.canvas = {};
    }
    if (!getTextWidth.canvas[line]) {
        getTextWidth.canvas[line] = document.createElement("canvas");
    }

    const canvas = getTextWidth.canvas[line];
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text);
    return metrics.width;
}

export const determineFontSize = (originalTextString, maxFontsize, minFontsize, maxFontWidth, fontWeight, fontSize, fontFamily, line, isCaps = false) => {
    let text = originalTextString;
    let oldText = text;
    if (isCaps) {
        text = text.toUpperCase();
    }

    let removedChars = 0;
    let fontWidth = getTextWidth(text, `${fontWeight} ${fontSize}px ${fontFamily}`, line);

    while (fontWidth > maxFontWidth && fontSize > minFontsize) {
        fontSize = Math.max(minFontsize, fontSize - 1);
        fontWidth = getTextWidth(text, `${fontWeight} ${fontSize}px ${fontFamily}`, line);
    }
    while (fontWidth < maxFontWidth && fontSize < maxFontsize) {
        fontSize = Math.min(maxFontsize, fontSize + 1);
        fontWidth = getTextWidth(text, `${fontWeight} ${fontSize}px ${fontFamily}`, line);
    }
    if (fontWidth > maxFontWidth && fontSize === minFontsize) {
        text = text.slice(0, -1).trimEnd();
        removedChars++;
        return determineFontSize(oldText.substring(0, oldText.length - removedChars), maxFontsize, minFontsize, maxFontWidth, fontWeight, fontSize, fontFamily, line, isCaps);
    }
    return {
        text: oldText.substring(0, oldText.length - removedChars),
        fontSize
    };
}

export const unfocusKeyBoard = (e) => {
    if (e.keyCode === 13) {
        e.target.blur();
    }
}