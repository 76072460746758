import { useTranslation } from "next-i18next"
import Link from "next/link"

const CountryMisMatch = ({ countryMisMatch, handleCountryMisMatch }) => {
    const { t } = useTranslation('common');
    return (
        <>
            <div onClick={() => handleCountryMisMatch()} className="fixed z-[999999999] inset-0 bg-black opacity-75">
            </div>
            <div
                className="max-md:max-w-[90vw] z-[999999999] md:w-fit mx-auto max-h-fit fixed bg-white w-full py-8 left-1/2 -translate-x-1/2 top-1/2 -translate-y-1/2 p-5 bottom-5 shadow-md rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between">
                <div className="max-md:w-full flex flex-col gap-4 max-w-md text-center items-center flex-shrink-0">
                    <h2>{t('countrymismatch.title')}</h2>
                    <p>{`${t('countrymismatch.text.first')} ${countryMisMatch.backendDomain} ${t('countrymismatch.text.last')}`}</p>
                    <div className="max-md:flex-col flex gap-4">
                        <button onClick={() => handleCountryMisMatch()} className="btn border max-md:py-4 px-4 rounded border-[#405952]">{t('countrymismatch.cancel')}</button>
                        <Link
                            onClick={() => handleCountryMisMatch()}
                            href={`https://${countryMisMatch.backendDomain}`} className="btn btn-primary">{t('countrymismatch.go.to')}<br /> {countryMisMatch.backendDomain}
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CountryMisMatch