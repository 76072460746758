export const event = ({ action, params }) => {
    try {
        window.pintrk('track', action, params);
    }
    catch {
        console.log('tracking unavailable');
    }
}

export function ptPageView() {
    event({
        action: 'pagevisit',
    })
}

export function ptAddToCartEvent(product_id, product_name, price, currency) {
    event({
        action: "addtocart",
        params: {
            value: price,
            currency: currency,
            line_items: [
                {
                    product_name: product_name,
                    product_id: product_id,
                    product_price: price
                }
            ]
        }
    })
}

export function ptBeginCheckoutEvent(cart) {
    event({
        action: "begincheckout",
        params: {
            "items": cart.items,
            "coupon": cart.couponCode
        }
    })
}

export function ptInitiatePaymentEvent() {
    event({
        action: "initiate_payment"
    })
}

export function ptPurchaseEvent(order) {
    event({
        action: "checkout",
        params: {
            value: order.value,
            order_quantity: order.items.reduce((acc, item) => acc + item.quantity, 0),
            currency: order.currency,
            order_id: order.transaction_id,
            promo_code: order.promo_code,
            line_items: order.items.map(item => {
                return {
                    product_name: item.name,
                    product_id: item.id,
                    product_price: item.price,
                    product_quantity: item.quantity
                }
            })
        }
    })
}




