import { fbPageView, fbViewContentEvent, fbAddToCartEvent, fbBeginCheckoutEvent, fbInitiatePaymentEvent, fbPurchaseEvent } from './facebook';
import { gAdsAddToCartEvent, gAdsBeginCheckoutEvent, gAdsPurchaseEvent } from './google-ads';
import { gaPageView, gaViewContentEvent, gaAddToCartEvent, gaShowBigImageEvent, gaEditCartItemEvent, gaAddOtherCartItemEvent, gaBeginCheckoutEvent, gaRemoveFromCartEvent, gaInitiatePaymentEvent, gaPurchaseEvent } from './google-analytics';
import { ptAddToCartEvent, ptBeginCheckoutEvent, ptInitiatePaymentEvent, ptPageView, ptPurchaseEvent } from './pinterest';
import { tiktokAddToCartEvent, tiktokBeginCheckoutEvent, tiktokPurchaseEvent, tiktokViewContentEvent } from './tiktok';
export async function triggerPageViewEvent(title, location, path) {
    if (!window) return;
    fbPageView();
    gaPageView(title, location, path);
    ptPageView();
}

export async function triggerViewContentEvent(value, currency, product_name, product_id) {
    if (!window) return;
    fbViewContentEvent(value, currency, product_id);
    gaViewContentEvent(product_id, product_name, value);
    tiktokViewContentEvent(product_id, product_name, value, currency);
}

export async function triggerAddToCartEvent(value, currency, product_id, product_name) {
    if (!window) return;
    fbAddToCartEvent(value, currency, product_id);
    gaAddToCartEvent(product_id, product_name, value);
    gAdsAddToCartEvent(product_id, product_name, value, currency);
    ptAddToCartEvent(product_id, product_name, value, currency);
    tiktokAddToCartEvent(product_id, product_name, value, currency);
}

export function triggerShowBigImageEvent() {
    if (!window) return;
    gaShowBigImageEvent();
}

export function triggerEditCartItemEvent() {
    if (!window) return;
    gaEditCartItemEvent();
}

export function triggerAddOtherCartItemEvent() {
    if (!window) return;
    gaAddOtherCartItemEvent();
}

export function triggerRemoveFromCartEvent(value, product_id, product_name) {
    if (!window) return;
    gaRemoveFromCartEvent(product_id, product_name, value);
}

export async function triggerBeginCheckoutEvent(cart) {
    if (!window) return;
    fbBeginCheckoutEvent(cart);
    gaBeginCheckoutEvent(cart);
    ptBeginCheckoutEvent(cart);
    gAdsBeginCheckoutEvent(cart);
}

export async function triggerInitiatePaymentEvent(orderData, cart, currency, locale) {
    if (!window) return;
    fbInitiatePaymentEvent(orderData, cart, currency, locale);
    gaInitiatePaymentEvent();
    ptInitiatePaymentEvent();
    tiktokBeginCheckoutEvent(cart, currency, locale);
}

export async function triggerPurchaseEvent(order) {
    if (!window) return;
    fbPurchaseEvent(order);
    gaPurchaseEvent(order);
    ptPurchaseEvent(order);
    gAdsPurchaseEvent(order);
    tiktokPurchaseEvent(order);
}
