import "../src/styles/style.scss";
import "../src/styles/main.scss";
import '../src/styles/header.scss';
import '../src/styles/_typography.scss';
import '../src/styles/external/mapfonts.css';
import '../src/styles/external/opensans.css';
import '../src/styles/external/worksans.css';
import '../src/styles/external/slick.css';
import '../src/styles/cms-page.scss';


import Cookies from 'js-cookie'
import Router from 'next/router';
import NProgress from 'nprogress';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { useEffect, useState } from "react";
import { useRouter } from 'next/router'
import { triggerPageViewEvent } from '../src/events/executors';
import { getParameterByName } from "../src/components/single-product/shared/helpers";
import { domainConfig } from "../domains.config";
import CountryMisMatch from "../src/components/CountryMismatch";
NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps }) {
  const { t } = useTranslation('common');
  const [showCookies, setShowCookies] = useState(false);
  const [countryMisMatch, setCountryMisMatch] = useState(null);
  const router = useRouter();

  const removeParameters = (url, paramNames) => {
    const urlParts = url.split('?');
    const urlWithoutParams = urlParts[0];
    const params = urlParts[1] ? urlParts[1].split('&') : [];
    const filteredParams = params.filter(param => {
      const paramName = param.split('=')[0];
      return !paramNames.includes(paramName);
    });
    return filteredParams.length ? `${urlWithoutParams}?${filteredParams.join('&')}` : urlWithoutParams;
  };

  useEffect(() => {
    if (!window) return
    triggerPageViewEvent(window.document.title, removeParameters(window.location.href, ['lat', 'lng']), window.location.pathname);
    Router.events.on("routeChangeComplete", (url) => {
      triggerPageViewEvent(window.document.title, removeParameters(window.location.href, ['lat', 'lng']), url);
    });
  }, [router.events]);

  const handleCookies = () => {
    setShowCookies(false);
    window.gtag && window.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
      personalization_storage: 'granted',
      functionality_storage: 'granted',
      security_storage: 'granted'
    });
    Cookies.set('WIH-cookies', 'granted', { expires: 365 });
  };

  useEffect(() => {
    if (!window) return

    const discountParam = getParameterByName('coupon');
    // Store discount param in session storage
    if (discountParam) {
      sessionStorage.setItem('discount', discountParam);
    }

    if (!Cookies.get('WIH-cookies')) {
      setTimeout(() => {
        setShowCookies(true);
      }, 5000);
    } else {
      window.gtag && window.gtag('consent', 'update', {
        ad_storage: 'granted',
        analytics_storage: 'granted',
        ad_user_data: 'granted',
        ad_personalization: 'granted',
        personalization_storage: 'granted',
        functionality_storage: 'granted',
        security_storage: 'granted'
      });
    }
  }, []);

  useEffect(() => {
    async function getCountry() {
      // Check the cookies first, to avoid unnecessary API calls
      // We store their preferenced country in a cookie, by router.locale
      // If the cookie exists, we return false so we don't have to make an API call
      if (Cookies.get(`WIH-country-${router.locale}`)) return false;
      const API_KEY = '1693db7890e923';
      try {
        const response = await fetch(`https://ipinfo.io/json?token=${API_KEY}`);
        const data = await response.json();
        return data.country;
      } catch (error) {
        console.error('Error fetching country data:', error);
        return null;
      }
    }

    getCountry().then(country => {
      if (!country) return;
      const domain = domainConfig[router.locale];

      if (domain.acceptedLocales.includes(country)) {
        Cookies.set(`WIH-country-${router.locale}`, 'granted', { expires: 365 });
        return;
      }

      // Check if country if used in another domain
      const countryMisMatch = Object.keys(domainConfig).find(key => {
        const domain = domainConfig[key];
        return domain.acceptedLocales.includes(country);
      });

      if (!countryMisMatch) {
        Cookies.set(`WIH-country-${router.locale}`, 'granted', { expires: 365 });
        return;
      }
      setCountryMisMatch(domainConfig[countryMisMatch])
    });
  }, []);

  const handleCountryMisMatch = () => {
    Cookies.set(`WIH-country-${router.locale}`, 'granted', { expires: 365 });
    setCountryMisMatch(null);
  }

  return (
    <>
      <Component {...pageProps} />
      {showCookies && (
        <div
          className="max-w-screen-lg z-[50] mx-auto fixed bg-white inset-x-5 p-5 bottom-5 shadow-md rounded-lg drop-shadow-2xl flex gap-4 flex-wrap md:flex-nowrap text-center md:text-left items-center justify-center md:justify-between">
          <div className="w-full">{t('cookie.message')}
          </div>
          <div className="flex gap-4 items-center flex-shrink-0">
            <button onClick={() => handleCookies()} className="bg-emerald-600 px-5 py-2 text-white rounded-md hover:bg-emerald-700 focus:outline-none">{t('cookie.agree')}</button>
          </div>
        </div>
      )}
      {countryMisMatch && <CountryMisMatch countryMisMatch={countryMisMatch} handleCountryMisMatch={handleCountryMisMatch} />}
    </>
  )
}

// MyApp.getInitialProps = async ({ ctx }) => {
//   const userIp = ctx.req.headers['x-forwarded-for'] || ctx.req.connection.remoteAddress;
//   const detectedIp = requestIp.getClientIp(ctx.req)
//   console.log(detectedIp);
// const API_KEY = '1693db7890e923';
// const userCountry = await fetch(`https://ipinfo.io/${detectedIp}/json?token=${API_KEY}`)
//   .then(response => response.json())
//   .then(data => data.country);
// console.log(userCountry)
//   return { userCountry };
// };

export default appWithTranslation(MyApp);

