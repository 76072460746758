export const event = ({ action, params }) => {
    window.gtag('event', action, params)
}

export function gaPageView(title, location, path) {
    event({
        action: "page_view",
        params: {
            page_title: title,
            page_location: location,
            page_path: path,
        }
    });
}

export function gaViewContentEvent(product_id, product_name, price) {
    event({
        action: "view_item",
        params: {
            "items": [
                {
                    "id": product_id,
                    "name": product_name,
                    "price": price,
                }
            ]
        }
    })
}

export function gaAddToCartEvent(product_id, product_name, price) {
    event({
        action: "add_to_cart",
        params: {
            "items": [
                {
                    "id": product_id,
                    "name": product_name,
                    "price": price
                }
            ]
        }
    })
}

export function gaShowBigImageEvent() {
    event({
        action: "show_big_image",
    })
}

export function gaEditCartItemEvent() {
    event({
        action: "edit_cart_item",
    })
}

export function gaAddOtherCartItemEvent() {
    event({
        action: "add_other_cart_item",
    })
}

export function gaRemoveFromCartEvent(product_id, product_name, price) {
    event({
        action: "remove_from_cart",
        params: {
            "items": [
                {
                    "id": product_id,
                    "name": product_name,
                    "price": price
                }
            ]
        }
    })
}

export function gaBeginCheckoutEvent(cart) {
    console.log('gaBeginCheckoutEvent', cart);
    event({
        action: "begin_checkout",
        params: {
            "items": cart.items,
            "coupon": cart.couponCode
        }
    });
    event({
        action: `checkout_button_${cart.checkoutButton}`
    });
}

export function gaInitiatePaymentEvent() {
    event({
        action: "initiate_payment",
        params: {
            event_category: "ecommerce",
        }
    })
}

export function gaPurchaseEvent(order) {
    event({
        action: "purchase",
        params: {
            "transaction_id": order.transaction_id,
            "value": order.value,
            "currency": order.currency,
            "shipping": order.shipping,
            "items": order.items
        }
    })
}