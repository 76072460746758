export const event = ({ action, params }) => {
    window.gtag('event', action, params)
}

export function gAdsAddToCartEvent(product_id, product_name, price, currency) {
    const callback = function () {
        if (typeof(url) != 'undefined') {
            window.location = url;
        }
    };

    gtag('event', 'conversion', {
        'send_to': 'AW-10992273176/yU6bCJScg-EDEJiOw_ko',
        'value': price,
        'currency': currency,
        'items': [{
            'id': product_id,
            'google_business_vertical': 'retail'
        }],
        'event_callback': callback
    });
    return false;
}

export function gAdsPurchaseEvent(order) {
    const { value, currency } = order;
    const callback = function () {
        if (typeof(url) != 'undefined') {
            window.location = url;
        }
    };
    gtag('event', 'conversion', {
        'send_to': 'AW-10992273176/5OhHCKrf--ADEJiOw_ko',
        'transaction_id': order.transaction_id,
        'event_callback': callback,
        'value': value,
        'currency': currency
    });
    return false;
}

export function gAdsBeginCheckoutEvent(cart) {
    const callback = function () {
        if (typeof(url) != 'undefined') {
            window.location = url;
        }
    };
    const currency = cart.currency;
    const value = cart.value;
    gtag('event', 'conversion', {
        'send_to': 'AW-10992273176/2bFjCI2x1-IDEJiOw_ko',
        'value': value,
        'currency': currency,
        'event_callback': callback
    });
    return false;
}
