import { amountStringToFloat } from "../functions";

export const event = ({ action, params }) => {
    try {
        window.ttq.track(action, params);
    }
    catch {
        console.log('tracking unavailable');
    }
}

export function tiktokViewContentEvent(product_id, product_name, value, currency) {
    event({
        action: 'ViewContent',
        params: {
            content_id: product_id,
            description: product_name,
            content_name: product_name,
            value: value,
            currency: currency,
            content_type: "product"
        }
    })
}

export function tiktokAddToCartEvent(product_id, product_name, price, currency) {
    event({
        action: "AddToCart",
        params: {
            value: price,
            currency: currency,
            content_id: product_id,
            content_name: product_name,
            description: product_name,
            content_type: "product",
        }
    })
}

export function tiktokBeginCheckoutEvent(cart, currency, locale) {
    const value = amountStringToFloat(cart?.totalProductsPrice, locale);
    event({
        action: "InitiateCheckout",
        params: {
            value: value,
            content_id: cart?.products?.map(p => { return { "content_id": p.id } }),
            currency: currency,
        }
    })
}

export function tiktokPurchaseEvent(order) {
    event({
        action: "CompletePayment",
        params: {
            value: order.value,
            quantity: order.items.reduce((acc, item) => acc + item.quantity, 0),
            currency: order.currency,
            content_id: order.transaction_id,
            content_type: "product_group",
            content_name: order.transaction_id,
            description: order.items.map(item => item.name).join(', '),
        }
    })
}




