import { fbEvent } from '@rivercode/facebook-conversion-api-nextjs';

import { v4 as uuid } from "uuid";
import { amountStringToFloat, check_cookie_name } from '../functions';

export function fbServerEvent(data) {
    fbEvent(data);
}

export function fbClientEvent(eventName, data, eventID) {
    if (!window || !window.fbq) return;

    const fbc = check_cookie_name("_fbc");

    window.fbq('track', eventName, {
        ...data,
        ...(fbc && { fbc })
    }, { eventID: eventID });
}

export function fbPageView() {
    const eventID = uuid();
    fbClientEvent('PageView', {}, eventID);
}

export function fbViewContentEvent(value, currency, product_id) {
    const eventId = uuid();
    fbClientEvent('ViewContent', {
        content_ids: [product_id],
        content_type: 'product',
        contents: [{
            id: product_id,
            quantity: 1,
        }],
        value,
        currency,
    }, eventId);

    fbServerEvent({
        eventName: 'ViewContent',
        products: [{
            sku: product_id.toString(),
            quantity: 1,
        }],
        eventId,
        value,
        currency
    });
}

export function fbAddToCartEvent(value, currency, product_id) {
    const eventId = uuid();

    fbClientEvent('AddToCart', {
        content_ids: [product_id],
        content_type: 'product',
        contents: [{
            id: product_id,
            quantity: 1,
        }],
        value,
        currency,
    }, eventId);

    fbServerEvent({
        eventName: 'AddToCart',
        products: [{
            sku: product_id.toString(),
            quantity: 1,
        }],
        eventId,
        value,
        currency
    });
}

export function fbBeginCheckoutEvent(cart) {
    const currency = cart.currency;
    const value = cart.value;
    const eventId = uuid();

    fbClientEvent('InitiateCheckout', {
        content_ids: cart?.items?.map(p => p.id),
        content_type: 'product',
        contents: cart?.items?.map(p => ({
            id: p.id,
            quantity: p.quantity,
        })),
        value,
        currency,
    }, eventId);

    fbServerEvent({
        eventName: 'InitiateCheckout',
        products: cart?.items?.map(item => ({
            sku: item.id.toString(),
            quantity: item.quantity,
        })),
        eventId,
        value,
        currency
    });
}

export function fbInitiatePaymentEvent(orderData, cart, currency, locale) {
    const value = amountStringToFloat(cart?.totalProductsPrice, locale);
    const eventId = uuid();

    fbClientEvent('AddPaymentInfo', {
        content_ids: cart?.products?.map(p => p.id),
        content_type: 'product',
        contents: cart?.products?.map(p => ({
            id: p.id,
            quantity: p.quantity,
        })),
        value,
        currency,
    }, eventId);

    fbServerEvent({
        eventName: 'AddPaymentInfo',
        products: cart?.products?.map(item => ({
            sku: item.productId.toString(),
            quantity: item.qty,
        })),
        emails: [orderData?.billing?.email],
        firstName: orderData?.billing?.firstName,
        lastName: orderData?.billing?.lastName,
        country: orderData?.billing?.country,
        city: orderData?.billing?.city,
        eventId,
        value,
        currency
    });
}


export function fbPurchaseEvent(order) {
    const { value, currency } = order;
    const eventId = uuid();

    fbClientEvent('Purchase', {
        content_ids: order?.items?.map(p => p.id),
        content_type: 'product',
        contents: order?.items?.map(p => ({
            id: p.id,
            quantity: p.quantity,
        })),
        value,
        currency,
    }, eventId);

    fbServerEvent({
        eventName: 'Purchase',
        products: order?.items?.map(item => ({
            sku: item.id.toString(),
            quantity: item.quantity,
        })),
        emails: [order?.email],
        firstName: order?.firstName,
        lastName: order?.lastName,
        country: order?.country,
        city: order?.city,
        value,
        currency,
        eventId
    });
}